var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',[(_vm.loading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('list-view',{key:_vm.listViewKey,attrs:{"headers":_vm.headers,"url":_vm.url,"row-actions":_vm.rowActions,"top-actions":_vm.topActions,"loading":_vm.loading,"items-per-page":25,"sort-by":"id","sort-desc":"","enable-filters":false,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250] }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.appendSitePrefix(("/auto-products/edit/" + (item.id)))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.event",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEventDescription(item.event))+" ")]}},{key:"item.productName",fn:function(ref){
var item = ref.item;
return [(!_vm.shouldShowMore(item.productName))?_c('span',[_vm._v(" "+_vm._s(item.productName)+" ")]):_c('span',[_c('a',{staticClass:"light-blue--text text-decoration-underline",on:{"click":function($event){return _vm.showMoreDialogToggle(item.productName)}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])])]}},_vm._l((_vm.itemFields),function(field){return {key:("item." + (field.prop)),fn:function(ref){
var item = ref.item;
return [(!_vm.shouldShowMore(_vm.getCorrectField(item.conditions, field.field)))?_c('span',[_vm._v(" "+_vm._s(_vm.getCorrectField(item.conditions, field.field))+" ")]):_c('span',[_c('a',{staticClass:"light-blue--text text-decoration-underline",on:{"click":function($event){_vm.showMoreDialogToggle(_vm.getCorrectField(item.conditions, field.field))}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])])]}}})],null,true)})],1)],1)],1),_c('v-dialog',{model:{value:(_vm.showMoreDialog),callback:function ($$v) {_vm.showMoreDialog=$$v},expression:"showMoreDialog"}},[_c('v-card',{staticClass:"v-card-active-show-more"},[_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.activeShowMoreText))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }