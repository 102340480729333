




































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import TemplateComponent from '@/components/TemplateComponent.vue'
import Tags from '@/components/tag/Tags.vue'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  methods: { appendSitePrefix },
  components: { Tags, TemplateComponent, PageTab, ListView },
})
export default class List extends Vue {
  private listViewKey = 0
  private loading = false

  private showMoreDialog = false
  private activeShowMoreText = ''

  private topActions = []
  private rowActions = []
  private headers = []

  private vehicleTypes = []
  private productTypes = []
  private productConditions = []
  private tyreIsRetreadedOptions = []
  private suppliers = []
  private webshops = []

  private events = [
    {
      id: 1,
      description: this.$t('c:auto-products:Product is added to order'),
    },
    {
      id: 2,
      description: this.$t('c:auto-products:New order is created'),
    },
    {
      id: 3,
      description: this.$t('c:auto-products:Product added to webshop order'),
    },
    {
      id: 4,
      description: this.$t('c:auto-products:Product added to webshop cart'),
    },
  ]

  private itemFields = [
    { prop: 'productType', field: 'product_type' },
    { prop: 'productCondition', field: 'product_condition' },
    { prop: 'tyreIsRetreaded', field: 'tyre_is_retreaded' },
    { prop: 'vehicleType', field: 'vehicle_type' },
    { prop: 'diameter', field: 'diameter' },
    { prop: 'width', field: 'width' },
    { prop: 'supplier', field: 'supplier' },
    { prop: 'webshopId', field: 'webshop_id' },
  ]

  async created() {
    this.setInitialHeaders()
    this.setRowActions()
    this.setTopActions()
    await this.load()
  }

  private async load() {
    this.loading = true

    await Promise.all([
      this.getSuppliers(),
      this.getProductTypes(),
      this.getVehicleTypes(),
      this.getProductConditions(),
      this.getWebshops(),
    ]).finally(() => {
      this.loading = false
      this.ifTyreIsRetreaded()
    })
  }

  private showMoreDialogToggle(text) {
    this.showMoreDialog = true
    this.activeShowMoreText = text
  }

  private shouldShowMore(text) {
    return false
  }

  private getEventDescription(eventNumber) {
    const event = this.events.find((event) => event.id === eventNumber)
    return event ? event.description : 'Unknown'
  }

  private ifTyreIsRetreaded() {
    this.tyreIsRetreadedOptions = [
      {
        id: 0,
        text: this.$t('No'),
      },
      {
        id: 1,
        text: this.$t('Yes'),
      },
    ]
    const tyreIsRetreadedFilter = {
      text: 'c:auto-products:If tyre is retreaded',
      value: 'tyreIsRetreaded',
      filter: {
        items: Object.keys(this.tyreIsRetreadedOptions).map((key) => {
          return {
            text: this.tyreIsRetreadedOptions[key].text,
            value: this.tyreIsRetreadedOptions[key].id,
          }
        }, this),
      },
      sortable: false,
    }
    this.headers.splice(2, 0, tyreIsRetreadedFilter)
  }

  private async getSuppliers() {
    this.$axios.get('v4/site/suppliers/connected').then((response) => {
      this.suppliers = response.data.data
      const supplierFilter = {
        text: 'Supplier',
        value: 'supplier',
        filter: {
          items: Object.keys(this.suppliers).map((key) => {
            return {
              text: this.suppliers[key].name,
              value: this.suppliers[key].id,
            }
          }, this),
        },
        sortable: false,
      }
      this.headers.splice(6, 0, supplierFilter)
    })
  }

  private async getVehicleTypes() {
    this.$axios.get('v4/site/products/enums/vehicle-types').then((response) => {
      this.vehicleTypes = response.data.data
      const vehicleTypeFilter = {
        text: 'c:auto-products:Vehicle type',
        value: 'vehicleType',
        filter: {
          items: Object.keys(this.vehicleTypes).map((key) => {
            return {
              text: this.vehicleTypes[key].text,
              value: this.vehicleTypes[key].id,
            }
          }, this),
        },
        sortable: false,
      }
      this.headers.splice(3, 0, vehicleTypeFilter)
    })
  }

  private async getProductTypes() {
    this.$axios.get('v4/site/products/types?includeShop=1&legacy=1&perPage=500').then((response) => {
      this.productTypes = response.data.data
      const productTypeFilter = {
        text: 'c:auto-products:Product type',
        value: 'productType',
        filter: {
          items: Object.keys(this.productTypes).map((key) => {
            return {
              text: this.productTypes[key].name,
              value: this.productTypes[key].id,
            }
          }, this),
        },
        sortable: false,
      }
      this.headers.splice(2, 0, productTypeFilter)
    })
  }

  private async getProductConditions() {
    this.$axios.get('v4/site/products/enums/conditions').then((response) => {
      this.productConditions = response.data.data
      const productConditionFilter = {
        text: 'c:auto-products:Product condition',
        value: 'productCondition',
        filter: {
          items: Object.keys(this.productConditions).map((key) => {
            return {
              text: this.productConditions[key].name,
              value: this.productConditions[key].id,
            }
          }, this),
        },
        sortable: false,
      }
      this.headers.splice(3, 0, productConditionFilter)
    })
  }

  private async getWebshops() {
    this.$axios.get('v4/site/get-webshops').then((response) => {
      this.webshops = response.data.data
      const webshopFilter = {
        text: 'c:auto-products:Webshop',
        value: 'webshopId',
        filter: {
          items: Object.keys(this.webshops).map((key) => {
            return {
              text: this.webshops[key].title,
              value: this.webshops[key].id,
            }
          }, this),
        },
        sortable: false,
      }
      this.headers.splice(4, 0, webshopFilter)
    })
  }

  private getCorrectField(conditions, field) {
    const fieldValue = conditions
      .filter((condition) => condition.field === field && condition.value !== '')
      .map((condition) => {
        try {
          const value = condition.value
          switch (field) {
            case 'webshop_id':
              if (value === null) {
                return ''
              }
              if (typeof value === 'number') {
                const webshop = this.webshops.find((webshop) => webshop.id === value)
                if (webshop !== undefined) {
                  return webshop.title
                }
              }
              if (typeof value === 'string' || Array.isArray(value)) {
                return this.webshops
                  .filter((item) => {
                    if (Array.isArray(value) || !value.includes(',')) {
                      return value.includes(item.id)
                    } else if (typeof value === 'string' && value.includes(',')) {
                      return value.split(',').includes(String(item.id))
                    }
                  })
                  .map((item) => item.title)
                  .join(', ')
              }
              return value
            case 'vehicle_type':
              if (value === null) {
                return ''
              }
              if (typeof value === 'number') {
                const vehicleType = this.vehicleTypes.find((vehicleType) => vehicleType.id === value)
                if (vehicleType !== undefined) {
                  return vehicleType.text
                }
              }
              if (typeof value === 'string' || Array.isArray(value)) {
                return this.vehicleTypes
                  .filter((item) => {
                    if (Array.isArray(value) || !value.includes(',')) {
                      return value.includes(item.id)
                    } else if (typeof value === 'string' && value.includes(',')) {
                      return value.split(',').includes(String(item.id))
                    }
                  })
                  .map((item) => item.text)
                  .join(', ')
              }
              return value
            case 'supplier':
              if (value === null) {
                return ''
              }
              if (typeof value === 'number') {
                const supplier = this.suppliers.find((supplier) => supplier.id === value)
                if (supplier !== undefined) {
                  return supplier.name
                }
              }
              if (typeof value === 'string' || Array.isArray(value)) {
                return this.suppliers
                  .filter((item) => {
                    if (Array.isArray(value) || !value.includes(',')) {
                      return value.includes(item.id)
                    } else if (typeof value === 'string' && value.includes(',')) {
                      return value.split(',').includes(String(item.id))
                    }
                  })
                  .map((item) => item.name)
                  .join(', ')
              }
              return value
            case 'product_type':
              if (value === null) {
                return ''
              }
              if (typeof value === 'number') {
                const productType = this.productTypes.find((productType) => productType.id === value)
                if (productType !== undefined) {
                  return productType.name
                }
              }
              if (typeof value === 'string' || Array.isArray(value)) {
                return this.productTypes
                  .filter((item) => {
                    if (Array.isArray(value) || !value.includes(',')) {
                      return value.includes(item.id)
                    } else if (typeof value === 'string' && value.includes(',')) {
                      return value.split(',').includes(String(item.id))
                    }
                  })
                  .map((item) => item.name)
                  .join(', ')
              }
              return value
            case 'product_condition':
              if (value === null) {
                return ''
              }
              if (typeof value === 'number') {
                const productCondition = this.productConditions.find(
                  (productCondition) => productCondition.id === value,
                )
                if (productCondition !== undefined) {
                  return productCondition.name
                }
              }
              if (typeof value === 'string' || Array.isArray(value)) {
                return this.productConditions
                  .filter((item) => {
                    if (Array.isArray(value) || !value.includes(',')) {
                      return value.includes(item.id)
                    } else if (typeof value === 'string' && value.includes(',')) {
                      return value.split(',').includes(String(item.id))
                    }
                  })
                  .map((item) => item.name)
                  .join(', ')
              }
              return value
            case 'tyre_is_retreaded':
              // eslint-disable-next-line no-case-declarations
              let shouldShow = false
              // eslint-disable-next-line no-case-declarations
              const productTypeCondition = conditions.find((condition) => condition.field === 'product_type')
              if (
                (parseInt(productTypeCondition.value) === 1 || parseInt(productTypeCondition.value) === 28) &&
                condition.operator !== 0
              ) {
                shouldShow = true
              }
              if (value === null || !shouldShow) {
                return ''
              }
              // eslint-disable-next-line no-case-declarations
              const matchedOption = this.tyreIsRetreadedOptions.find((option) => option.id === parseInt(value))
              return matchedOption.text
            default:
              return Array.isArray(value) ? value.join(', ') : value
          }
        } catch (error) {
          vxm.alert.onAxiosError(error, 'Failed to load field data')
          return ''
        }
      })
    return fieldValue.join(', ')
  }

  private setRowActions() {
    this.rowActions = [
      {
        id: 'edit',
        route: (item) => {
          return {
            name: 'AutoProducts/Edit',
            params: { id: item.id },
          }
        },
      },
      {
        id: 'delete',
      },
    ]
  }

  private setTopActions() {
    this.topActions = [
      {
        id: 'new',
        template: 'new',
        label: 'c:auto-products:New auto product',
        route: () => {
          return {
            name: 'AutoProducts/New',
          }
        },
      },
    ]
  }

  get url() {
    return '/v4/site/auto-products'
  }

  private setInitialHeaders() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Event',
        value: 'event',
        filter: {
          items: Object.keys(this.events).map((key) => {
            return {
              text: this.events[key].description,
              value: this.events[key].id,
            }
          }, this),
        },
      },
      {
        text: 'Diameter',
        value: 'diameter',
      },
      {
        text: 'Width',
        value: 'width',
      },
      {
        text: 'Product',
        value: 'productName',
      },
      {
        text: 'Price',
        value: 'productPrice',
        sortable: false,
      },
      {
        text: 'Quantity',
        value: 'productQuantity',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]
  }
}
